import { Controller } from "@hotwired/stimulus"

import {Turbo} from "@hotwired/turbo-rails"


let throttle = require('lodash/throttle');


export default class extends Controller {
  static targets = ["slide", "frames", "frame", "nextButton", "previousButton", "navbar", "goBackButton", "nonSlide"]


  static values = { index: Number }
  // rowUrl: String, conversationUrl: String - THESE are written as values so it can be easily linked to this stimulus controller

  static classes = [ "hide", "highlight" ] 

  // If you're reading this, my compliments for looking in the source code!
  // Feel free to email: ben.chenathara@gmail.com for further information. 
   

 // Here's how it works.
 // slideshow-controller
      // nav bar with all the buttons
      // conversations --> turbo_frame

        // pagy_data controller -- with pagy meta data information

           // slideTarget
              // frameTarget --> turbo_frame


      // when a row link is clicked this forces a setIndexValue event
      // when results are reloaded etc. then the pagy meta data is refreshed.
      // the next and go back and previous buttons all work, considering pagination.
      // the history is also updated with this happens.

  
  connect(){          
    this.next = throttle(this.next, 1000).bind(this)
    this.previous = throttle(this.previous, 1000).bind(this)              
  }

  next(event) {
    event.preventDefault()       

    if (this.pagyMetadata.next != null && this.indexValue ===  (this.slideTargets.length - 1)  ){ 
     this.framesTarget.src =  this.pagyMetadata.next_url     

     window.history.pushState({}, "Conversations", this.pagyMetadata.next_url) 

     this.framesTarget.loaded.then(function(){this.indexValue = 0                          
                                              this.frameTargets[0].src = this.frameTargets[0].getAttribute("data-slideshow-conversation-url-value")
                                              }.bind(this))      
    }
    else
    {
      // let's get the next slide
      // let's get the SRC of the next slide
      // let's update the index
      // and let's trigger the target
      this.revertCurrentSlideToRowView() // this reverts a slide when we move fwd. This might prove problematic in the future

      this.indexValue = this.indexValue + 1

      let frame = this.frameTargets[this.indexValue]      

      frame.src = frame.getAttribute("data-slideshow-conversation-url-value")
      window.history.pushState({}, "Conversations", frame.getAttribute("data-slideshow-conversation-url-value") )
    }            
  }  

  previous(event) {
    event.preventDefault()      

    if (this.pagyMetadata.prev != null && this.indexValue ===  0  ){      
      this.framesTarget.src = this.pagyMetadata.prev_url  
      window.history.pushState({}, "Conversations", this.pagyMetadata.prev_url )

      this.framesTarget.loaded.then(function(){
                                              let arrayLength = this.frameTargets.length                          
                                              this.indexValue = arrayLength - 1
                                              this.frameTargets[arrayLength - 1].src = this.frameTargets[arrayLength - 1].getAttribute("data-slideshow-conversation-url-value")
                                              }.bind(this))    
    }
    else
    {
      // let's get the next slide
      // let's get the SRC of the next slide
      // let's update the index
      // and let's trigger the target
      this.revertCurrentSlideToRowView() // this reverts a slide when we move fwd. This might prove problematic in the future

      this.indexValue = this.indexValue - 1

      let frame = this.frameTargets[this.indexValue];
      frame.src = frame.getAttribute("data-slideshow-conversation-url-value")

      window.history.pushState({}, "Conversations", frame.getAttribute("data-slideshow-conversation-url-value") )
    }                 
  }

  setIndexValue(event){    
    const link = event.target;
    window.history.pushState({}, `Conversation ID: ${this.indexValue}`, link.href )

    let conversationId = link.dataset.slideshowConversationId;           
    
    let indexValue = this.slideTargets.findIndex(function(slide){
        if (slide.dataset.slideshowConversationId == this){          
          return true                    
        }     
      }.bind(conversationId))   

    if (indexValue === this.indexValue){
      this.showCurrentSlide()
      this.showNavBar()
      this.hideNonSlides()
      this.updateNavBarButtons() 
    }
    else
    {
       this.indexValue = indexValue;
    }
  }
  
  goBack(event){    
    event.preventDefault()
    this.hideNavBar()
    this.showAllSlides()
    this.showNonSlides()
    this.revertCurrentSlideToRowView()    
  }   

  indexValueChanged() {    
    // this.handleNavigationButtonDisplay()   
    if (this.indexValue >= 0) {              
      this.showCurrentSlide()      
      this.showNavBar()
      this.hideNonSlides()
      this.updateNavBarButtons() 
    }       
  }

  showNonSlides(){
    this.nonSlideTargets.forEach((e) => {
      e.classList.remove(this.hideClass)
    }, this)
  }

  hideNonSlides(){
    this.nonSlideTargets.forEach((e) => {
      e.classList.add(this.hideClass)
    }, this)
  }

  revertCurrentSlideToRowView(){
    // when we go back, we want to get the current slide's row view.
    // we must hit this endpoint.
    // we must get the current URL

    this.frameTargets.forEach((frame, index) => {  
        if (index == this.indexValue){ 
          frame.src = frame.getAttribute("data-slideshow-row-url-value")
        }        
      }, this)

    // let's highlight the current slide
    // and scroll into view
    this.slideTargets.forEach((slide, index) => {  
        if (index == this.indexValue){ 
          slide.classList.add(...this.highlightClasses)
          slide.scrollIntoView(true)
        }
        else
        {
          slide.classList.remove(...this.highlightClasses)
        }        
      }, this)    
   
  }

  showNavBar(){
    this.navbarTarget.classList.remove(this.hideClass)          
  }

  hideNavBar(){
    this.navbarTarget.classList.add(this.hideClass)
  }

  updateNavBarButtons(){    
    if (this.pagyMetadata.prev == null && this.indexValue === 0){
      this.previousButtonTarget.disabled = true
      // this.previousButtonTarget.classList.add(this.hideClass)
    }
    else
    {
      // this.previousButtonTarget.classList.remove(this.hideClass)
      this.previousButtonTarget.disabled = false
    }    

    if (this.pagyMetadata.next == null && this.indexValue ===  (this.slideTargets.length - 1)  ){
      this.nextButtonTarget.disabled = true
      // this.nextButtonTarget.classList.add(this.hideClass)      
      // this.nextButtonTarget.classList.add(this.hideClass)      
    }
    else
    {
      this.nextButtonTarget.disabled = false
      // this.nextButtonTarget.classList.remove(this.hideClass)
    }
  }

  showAllSlides(){
    this.slideTargets.forEach(slide => slide.classList.remove(this.hideClass), this)
  }

  showCurrentSlide(){
      // get current slide
      // if it is not current either hide or show it      
      this.slideTargets.forEach((slide, index) => {  
        if (index == this.indexValue){
          slide.classList.remove(this.hideClass)                      
        }
        else{
          slide.classList.add(this.hideClass)           
        }        
      }, this)
  }


  pagyConnected(event){        
    this.pagyMetadata = event.detail
  }

}