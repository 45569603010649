

import { Controller } from "@hotwired/stimulus"

// success target
// source target
// to-copy-target

export default class extends Controller {

  static classes = ['hide']

  static targets = ['copyIndicator', 'source', 'success']

  static values = {
    successDuration: {
      type: Number,
      default: 4000
    }
  }

  connect (){    
    if (!this.hasCopyIndicatorTarget) return    
    this.successTarget.classList.add(this.hideClass)
  }


  copy(event){
    event.preventDefault()

    this.sourceTarget.select()
    document.execCommand('copy')

    this.copied()
  }


  copied () {
    if (!this.hasCopyIndicatorTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    // this.buttonTarget.innerText = this.data.get('successContent')
    this.successTarget.classList.remove(this.hideClass)
    this.copyIndicatorTarget.classList.add(this.hideClass)

    this.timeout = setTimeout(() => {
      this.successTarget.classList.add(this.hideClass)
      this.copyIndicatorTarget.classList.remove(this.hideClass)
    }, this.successDurationValue)
  }

}
