import {
  Controller
} from "@hotwired/stimulus"


let debounce = require('lodash/debounce');
let throttle = require('lodash/throttle');


export default class extends Controller {
  static targets = ["form", "pageInput"]

  connect() {
    this.submitAndRevertPageNumber = debounce(this.submitAndRevertPageNumber, 350).bind(this)
    this.submitAndRevertPageNumber = throttle(this.submitAndRevertPageNumber, 350).bind(this)
  }

  submitAndRevertPageNumber(event) {    
    let pageInput = this.pageInputTarget
    pageInput.value = 1
    this.formTarget.requestSubmit()
  }

  submitForm(event) {
    event.preventDefault()

    let pageNumber = event.target.getAttribute("href")

    if (pageNumber === "#") {
      // then do nothing
    } else {
      // let's get the form's page input and replace it with the link's page number
      let pageInput = this.pageInputTarget
      pageInput.value = pageNumber

      // and now let's just click the submit button      
      this.formTarget.requestSubmit()
    }
  }
}