import { Controller } from "@hotwired/stimulus"

/*    <div data-controller="show-on-hover" data-show-on-hover-hide-class="d-none" data-action="mouseover->show-on-hover#show mouseout->show-on-hover#hide">
          <button data-show-on-hover-target="item">
          </button>        
          <div data-show-on-hover-target="item">
          </div>        
      </div>
*/

export default class extends Controller {
  static targets = [ "item" ]
  static classes = [ "hide" ] 

  connect() {    
  	this._hide()
  }

  show(event){
  	this.itemTargets.forEach(item => item.classList.remove(this.hideClass))	

  }

  hide(event){
  	this._hide()
  }

  _hide(){
  	this.itemTargets.forEach(item => item.classList.add(this.hideClass))
  }
}
