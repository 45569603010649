import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect(){          
   this.onScrollRunning = false      
   this.elementYPoint = this.element.getBoundingClientRect().bottom + window.pageYOffset;
  }    

  onScroll(event) {    
    if (!this.onScrollRunning) {      
      this.onScrollRunning = true;
      if (window.requestAnimationFrame) {        
        window.requestAnimationFrame(this.scroll.bind(this));
      } else {         
         setTimeout(this.scroll.bind(this), 66);
      }
     }
  }

  scroll() {
    let windowBottomPosition = window.scrollY + window.innerHeight    
    
    // console.log(`window bottom position: ${window.scrollY + window.innerHeight}`)
    // console.log(`element y point: ${this.elementYPoint}`)

    if (windowBottomPosition >= this.elementYPoint) {
      if (! this.element.classList.contains("position-relative")) {        
        this.element.classList.add("position-relative")
      }

      if (this.element.classList.contains("fixed-bottom")) {        
        this.element.classList.remove("fixed-bottom")
      }

    } else  
    { 
      if (! this.element.classList.contains("fixed-bottom")) {        
        this.element.classList.add("fixed-bottom")
      }

      if (this.element.classList.contains("position-relative")) {        
        this.element.classList.remove("position-relative")
      }
    }
    this.onScrollRunning = false
  }
}