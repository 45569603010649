import { Controller } from "@hotwired/stimulus"

import { Elm } from '../Main'

// import { Elm } from '../Decider'
// import {Elm} from '../Counter'

import {Sha1Module} from "../sha1Js/sha1"

export default class extends Controller {

  static targets = [ "sha1App", "elmApp", "downloadApp"]

  connect(){
    this.setUpElmAppAndJoblesApp()    
    this.setUpDownloadAppAndJoblesApp()    
  }


    setUpDownloadAppAndJoblesApp(){
      if (this.hasDownloadAppTarget) {        
          let node = this.downloadAppTarget
          let documents = JSON.parse(node.getAttribute('data-documents'))
          let user = JSON.parse(node.getAttribute('data-user'))
          let conversation = JSON.parse(node.getAttribute('data-conversation'))
          let message = JSON.parse(node.getAttribute('data-message'))      

          var elmApp = Elm.Decider.init({node: node, flags: {documents: documents, message: message}  })          
          
          if (documents.length > 0) {
              let joblesApp = Sha1Module.init({
                node: this.sha1AppTarget,
                callback: passShaToElm
              })

              function passShaToElm(json_payload){ 
                elmApp.ports.fileReceiver.send(JSON.parse(json_payload))
              }
          }
      }
    }    

  setUpElmAppAndJoblesApp(){
      if (this.hasElmAppTarget)
      {
          let node = this.elmAppTarget
          let documents = JSON.parse(node.getAttribute('data-documents'))
          let user = JSON.parse(node.getAttribute('data-user'))
          let conversation = JSON.parse(node.getAttribute('data-conversation'))
          let message = JSON.parse(node.getAttribute('data-message'))      

          if (user !== null) {
            // then we use the joble version of the app:
            var elmApp = Elm.Main.init({node: node, flags: {documents: documents, user: user, project_id: conversation.project_id, message: message}  })          
          }             
          else{
            // then we use the decider version of the app
            var elmApp = Elm.Decider.init({node: node, flags: {documents: documents, message: message}  })          
          }      
          
          if (documents.length > 0) {
              let joblesApp = Sha1Module.init({
                node: this.sha1AppTarget,
                callback: passShaToElm
              })

              function passShaToElm(json_payload){ 
                elmApp.ports.fileReceiver.send(JSON.parse(json_payload))
              }
          }
      }
  }
}