import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static classes = ["highlight"]  

  static values = { matchingId: String}  

  static targets = ["highlight"]  

  connect() {
    this.onHashChange()
    window.addEventListener("hashchange", this.onHashChange.bind(this))
  }

  disconnect(){
     window.removeEventListener("hashchange", this.onHashChange.bind(this)) 
  }

  onHashChange(e){
    if(window.location.hash)
    {
      this.anchor = window.location.hash.substring(1)      
      
      if (this.isAnchorMatching())
      {
        this.highlight()
      }
      else
      {
        this.unhighlight()
      }
    }
  }
  
  isAnchorMatching(){
    return this.matchingIdValue === this.anchor
  }
  
  highlight(){
    this.element.classList.add(...this.highlightClasses)          

    let targetClassName = this.highlightTargetClassName()

    this.highlightTargets.forEach((t) => {
      if(t.getAttribute(targetClassName)){        
        t.classList.add(...t.getAttribute(targetClassName).split(" "))        
      }
      
    }, this)
  }

  unhighlight(){
    this.element.classList.remove(...this.highlightClasses)          

    let targetClassName = this.highlightTargetClassName()
    
    this.highlightTargets.forEach((t) => {
      if(t.getAttribute(targetClassName)){        
        t.classList.remove(...t.getAttribute(targetClassName).split(" "))        
      }
      
    }, this)
  }


  highlightTargetClassName(){
    return "data-" + this.identifier + "-highlight-class"
  }
}

/*

// we need to style an element, if the anchor matches the element
// in a particular way.

<div data-controller="anchor-styler" 
     data-anchor-styler-highlight-class="shadow shadow-lg border border-primary border-2 mb-5 mt-5" 
     data-anchor-styler-matching-id-value="<%= dom_id(@active_record_instance)%>">

     <p data-anchor-styler-target="highlight" data-anchor-styler-highlight-class="text-primary" >       
         This will only be styled if the anchor matches
      </p>

</div>

// This will allow you to style
// matching elements, however your want to.
// if you need complex matching logic, then you can simply
// override methods 
// and you can style the elements however you like
*/