import { Controller } from "@hotwired/stimulus";

// this works with bootstrap.
// inspired by Javan's code here: https://discuss.hotwire.dev/t/tabbed-interface-with-stimulus/65


export default class extends Controller {
  static targets = [ "tab", "panel" ]

    initialize() {
      this.showTab()
    }

    change(event) {      
      event.preventDefault()

      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    showTab() {            
      this.tabTargets.forEach((tab, index) => {
        const panel = this.panelTargets[index]
        tab.classList.toggle("active", index == this.index)          

        panel.classList.toggle("show", index == this.index)
        panel.classList.toggle("active", index == this.index)
      })
    }

    get index() {
      return parseInt(this.data.get("index") || 0)
    }

    set index(value) {
      this.data.set("index", value)
      this.showTab()
    }
}