import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  connect(){    
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;       
  }

  show() {
   this.timeout = setTimeout(this.show_without_timeout.bind(this), 500);
  }

  show_without_timeout(){
     if (this.hasCardTarget) {
      this.cardTarget.classList.remove("invisible");
    } else {   

        if (this.signal.aborted) {
          this.abortController = new AbortController();
          this.signal = this.abortController.signal;    
        }
      
        fetch(this.urlValue, {signal: this.signal})
          .then((r) => r.text())
          .then((html) => {
            let fragment = document
              .createRange()
              .createContextualFragment(html);

            this.element.appendChild(fragment);})
          .catch( function(err){            
              if (err.name == 'AbortError') { // handle abort()                
              } else {
                throw err;
              }
          });
    }
  }

  hide() {    
    clearTimeout(this.timeout);

    if (this.signal.aborted) {
    }
    else
    {
      this.abortController.abort()     
    }    

    if (this.hasCardTarget) {      
      this.cardTarget.classList.add("invisible")      
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}