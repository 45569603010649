import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static values = { matchingId: String, url: String}    

  connect() {    
    this.onHashChange()
    window.addEventListener("hashchange", this.onHashChange.bind(this))
  }

  disconnect(){
     window.removeEventListener("hashchange", this.onHashChange.bind(this)) 
  }

  onHashChange(e){
    if(window.location.hash)
    {
      this.anchor = window.location.hash.substring(1)      
      
      if (this.isAnchorMatching())
      {
        this.driveFrame()
      }
      else
      {
        
      }
    }
  }
  
  isAnchorMatching(){
    return this.matchingIdValue === this.anchor
  }

  driveFrame(){    
    this.element.src = this.urlValue
  }

}

/*

// we need to style an element, if the anchor matches the element
// in a particular way.

// and the anchor is #message_104 (where 104 is the message.id)
<%= turbo_frame_tag message, "data-controller":"anchor-frame-driver", 
                             "data-anchor-frame-driver-matching-id-value":dom_id(message),
                             "data-anchor-frame-driver-url-value":message_path(message)  do  %>
<% end %>


// This will allow you to style
// matching elements, however your want to.
// if you need complex matching logic, then you can simply
// override methods 
// and you can style the elements however you like
*/