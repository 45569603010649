import {
	Controller
} from "@hotwired/stimulus";

/*
  <div data-controller="tippy">
    <div data-controller="frame-loader">
      <div data-tippy-target="body" data-action="mouseenter->frame-loader#trigger"
        data-frame-loader-url-param="<%= receipt_path(@receipt) %>">
        <p class="d-inline me-1">
          <%= @receiver.full_name %>
          <% if @receipt.is_read %>
            <%= render(Primer::OcticonComponent.new(:"check", fill: "green")) %>
          <% else %>
            <%= render(Primer::OcticonComponent.new(:"x", fill: "red")) %>
          <% end %>
        </p>
      </div>
      <%= turbo_frame_tag @receipt, "data-frame-loader-target":"frame" do %>
        <!-- the returned element should be a <div data-tippy-target="tooltip" /> -->
      <% end %>
    </div>
  </div>

*/

export default class extends Controller {

	static targets = ["frame"];

	connect() {
		this.abortController = new AbortController();
		this.signal = this.abortController.signal;

		this.hasLoaded = false;
	}

	trigger(event) {
		let url = event.params.url;
		this.timeout = setTimeout(this.show_without_timeout.bind(this, url), 500);
	}

	show_without_timeout(url) {
		if (this.hasLoaded) {
			// do nothing if the frame has already been loaded.
		} else {
			if (this.signal.aborted) {
				this.abortController = new AbortController();
				this.signal = this.abortController.signal;
			}

			this.frameTarget.src = url;
		}
	}
}