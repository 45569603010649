import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  static classes = [ "hide"]

  connect(){

  }

  hide(e) {    
    this.contentTargets.forEach((t) => t.classList.toggle(this.hideClass));        
  }

  show(e) {    
    this.contentTargets.forEach((t) => t.classList.toggle(this.hideClass));    
  }
}
