Trix.config.textAttributes.red = { 
	style: { color: "red" },
  parser: function(element) {
  	return element.style.color === "red"
  },
  inheritable: true
 }

Trix.config.textAttributes.blue = { 
	style: { color: "blue" },
  parser: function(element) {
  	return element.style.color === "blue"
  },
  inheritable: true
 }
 
 Trix.config.textAttributes.green = { 
	style: { color: "green" },
  parser: function(element) {
  	return element.style.color === "green"
  },
  inheritable: true
 }


addEventListener("trix-initialize", function(event) {  
  var buttonHTML = `
    <details class="dropdown">
    	<summary class="button">
        🎨
      </summary>
      
      <div class="menu">
        <div class="colors">
          <button type="button" data-trix-attribute="red" class="colors__item colors__item--red">Red</button>
          <button type="button" data-trix-attribute="blue" class="colors__item colors__item--blue">Blue</button>
          <button type="button" data-trix-attribute="green" class="colors__item colors__item--green">Green</button>
         </div>
      </div>
    </details>
  	
  `

	event.target.toolbarElement.
  	querySelector(".trix-button-group--text-tools").
		insertAdjacentHTML("beforeend", buttonHTML);
    
 
  // Close dropdown

  const details = document.querySelectorAll(".dropdown");

  details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", () => {
      details.forEach((detail) => {
        detail.removeAttribute("open");
      });
    });
  });
})
