import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  Dashboard,
  XHRUpload,
  AwsS3,
  AwsS3Multipart,
} from 'uppy'

const randomstring = require('randomstring')

const singleFileUpload = (fileInput, imagePreviewElement, uploadResult) => {
  const imagePreview = imagePreviewElement
  const formGroup    = fileInput.parentNode 

  formGroup.removeChild(fileInput)

  const uppy = fileUpload(fileInput)

  uppy
    .use(FileInput, {
      target: formGroup,
      locale: { strings: { chooseFiles: 'Open Camera / Choose file' } },
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })

  uppy.on('upload-success', (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput)
    uploadResult.value = fileData
    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
  })

  uppy.on('thumbnail:generated', (file, preview) => {    
    imagePreview.src = preview
  })
}

const multipleFileUpload = (fileInput) => {
  const formGroup = fileInput.parentNode

  const uppy = fileUpload(fileInput)

  uppy
    .use(Dashboard, {
      target: formGroup,
      inline: true,
      height: 300,
      width: 1200,
      replaceTargetContent: true,
      theme: 'dark'
    })

  uppy.on('upload-success', (file, response) => {
    const hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    // it used to be the follow when we just had a message model and controller + accepts
    // nested attributes etc.
    // hiddenField.name = `message[documents_attributes][${randomstring.generate()}][file]`
    hiddenField.name = `conversation_starter[message][documents_attributes][${randomstring.generate()}][file]`
    hiddenField.value = uploadedFileData(file, response, fileInput)

    document.querySelector('form').appendChild(hiddenField)
  })
}

const fileUpload = (fileInput) => {
  const uppy = Core({
    id: fileInput.id,
    autoProceed: true,
    /*,    
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
    }*/
  })

  // default upload ti s3_multipart set on the data attributes of the uploaded files:

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/' // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })
  } else {
    uppy.use(XHRUpload, {
      endpoint: '/upload', // Shrine's upload endpoint
    })
  }

  return uppy
}

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == 's3') {
    //((https):\/\/storage.googleapis.com\/)([-a-zA-Z0-9@:%._\+~#=]+)\/([a-zA-Z0-9]+)\/([-a-zA-Z0-9@:%._\+~#=]+)
    // if we use the google method:
    // note that for this we are assuming that the cache is removed from being considered in the regex.
    // const id = file.xhrUpload.endpoint.match("((https):\/\/storage.googleapis.com\/)([-a-zA-Z0-9@:%._\+~#=]+)\/([a-zA-Z0-9]+)\/([-a-zA-Z0-9@:%._\+~#=]+)")[5];    

    // if we're using an aws bucket and we have set the prefix to 'cache'
    let id = file.meta['key'].match(/^cache\/(.+)/)[1]; // object key without prefix        

    return JSON.stringify(fileData(file, _id))
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {    
    
    let id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix
    let fileDataStringified = JSON.stringify(fileData(file, id))

    return fileDataStringified
  } else 
  {
    return JSON.stringify(response.body)
  }
}

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: 'cache',
  metadata: {
    size:      file.size,
    filename:  file.name,
    mime_type: file.type    
  }
})

export { singleFileUpload, multipleFileUpload }