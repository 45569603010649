import { Controller } from "@hotwired/stimulus";

export default class extends Controller {  
  static targets = [ "ccEmails", "ccEmailsInput", "toEmails", "toEmailsInput"]  

  static values = { isPostRequest: Boolean }
  connect()
  {    

  }

  disconnect() {    
  }


  submit(event){    
    if (this.areEmailAddressesExisting() && this.areEmailInputsEmpty()) 
    {

    }
    else
    {   
      event.preventDefault()
    }    
  }

  areEmailAddressesExisting(){
    if (this.noEmailsAdded() && this.isPostRequestValue) 
    {
      alert("Please enter a recipient, or a valid email address.");      
      this.toEmailsInputTarget.classList.add("alert","alert-danger");
      return false;
    }
    else
    {
      return true;
    }
  }

  noEmailsAdded(){
    return !(this.toEmailsTarget.options.length > 1 || this.ccEmailsTarget.options.length > 1)
  }

  addValidationColors(){
    if (! (this.toEmailsTarget.options.length > 1 || this.ccEmailsTarget.options.length > 1)) 
    {      
     this.toEmailsInputTarget.classList.add("alert","alert-danger"); 
     this.toEmailsInputTarget.classList.remove("is-valid");             
    }
    else
    {
      this.toEmailsInputTarget.classList.remove("alert","alert-danger");             
    }
  }

  areEmailInputsEmpty(){   
    return this.toEmailsInputTarget.value === "" && this.ccEmailsInputTarget.value === "";
  }
}