import { Controller } from "@hotwired/stimulus";

// this works with bootstrap.
// inspired by Javan's code here: https://discuss.hotwire.dev/t/tabbed-interface-with-stimulus/65


export default class extends Controller {
    static values = {"metadata": Object} 

    initialize() {                 
          const event = document.createEvent("CustomEvent")
          event.initCustomEvent("pagy-connected", true, true, this.metadataValue)
          this.element.dispatchEvent(event)
    }   
}