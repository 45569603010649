import {
  Controller
} from "@hotwired/stimulus";

// https://stackoverflow.com/questions/5320404/wrap-link-a-around-div
// when we have a turbo_frame
// we might want to simulate link_to

export default class extends Controller {
  static targets = ["link", "content"]

  /*
      <div data-controller="trigger-turbo-frame" data-trigger-turbo-frame-target="content">
        <link_to link, data-trigger-turbo-frame-target="link" />
        ... on clicking the content - we drive the link manually.
        ... otherwise we do nothing.
        <div>
        </div>
      <div>
  */
  initialize() {

  }

  triggerFrame(event) {
    if (this.hasLinkTarget) {
      this.linkTarget.click();
    }
  }
}