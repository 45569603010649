import { Controller } from "@hotwired/stimulus";

// https://github.com/hotwired/stimulus/issues/200#issuecomment-434731830
// We create an even on a outer controller.

export default class extends Controller {
  connect() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("inner-connected", true, true, null)
    this.element.dispatchEvent(event)
  }
}