import { Controller } from "@hotwired/stimulus";

let debounce = require('lodash/debounce');
let throttle = require('lodash/throttle');

export default class extends Controller {
  static targets = ["submitButton"]

  connect(){
  	this.submit = debounce(this.submit, 250).bind(this)
    this.submit = throttle(this.submit, 250).bind(this)
  }
  
  submit(event) {        
    this.element.requestSubmit();
  };

  reset(event){    
    this.submitButtonTarget.disabled = false;
  }
}