import { 	patch } from '@rails/request.js'

import {	Controller} from "@hotwired/stimulus"

export default class extends Controller {

	static values = { url: String }

	connect(){
		patch(this.urlValue, {				
				responseKind: "turbo-stream"
		})
	}
}