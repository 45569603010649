/// remove if we are not using this.


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };


  connect(){    
  }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          let fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.innerHTML = html;

        });
    }
  }

  hide() {    
    if (this.hasCardTarget) {      
      this.cardTarget.classList.add("d-none");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}