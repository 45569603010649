import {
	Controller
} from "@hotwired/stimulus";

import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

export default class extends Controller {

	// the body should be the target upon which the tooltip goes
	static targets = ["tooltip", "body"];

	connect() {
		this.tooltipAdded = this.tooltipAdded.bind(this);

		if (this.hasTooltipTarget && this.hasBodyTarget) {
			this.setupTooltip(this.tooltipTarget)
		}
	}

	tooltipAdded(event) {
		this.setupTooltip(event.target)
	}

	bodyTargetDisconnected() {
		if (typeof variable !== 'undefined') {
    			this.tippyInstance.destroy();
		}
	}

	tooltipTargetConnected(target) {
		this.setupTooltip(target)
	}

	setupTooltip(target) {
		this.tippyInstance = tippy(this.bodyTarget, {
			content: target,
			arrow: true,
			allowHTML: true,
			theme: 'light',
			delay: [50, null],
			touch: true,
			interactive: true,
			appendTo: () => document.body
		});
		this.tippyInstance.show();
	}
}