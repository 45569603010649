import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

	static targets = [ "submitButton" ]
	static classes = [ "success", "disabled" ]

	connect(){		
	}

	onSubmit(){											
    	this.submitButtonTarget.value = "Notifications sent"
    	this.submitButtonTarget.className = ""    	
    	this.submitButtonTarget.classList.add(...this.successClasses)
    	this.submitButtonTarget.classList.add(this.disabledClass)
    	this.submitButtonTarget.disabled = true
	}	
}