// basically handles validation rules and styling


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "correspondenceType", "correspondenceTypeAlert", "toEmailsAlert", "toEmails", "ccEmails", "subjectAlert", "subject", "toEmailsInput", "ccEmailsInput" ]

  connect() {   
  }

  submit(event){  	
  	if (this.isCorrespondenceTypeValid() && this.isSubjectValid() && this.areThereAnyRecipients()) 
  	{  	  		
		
  	}
  	else
  	{   
  		event.preventDefault()
  	}
  }

  isSubjectValid(){  	
  	if (this.hasSubjectTarget) 
  	{	
	  	if (this.subjectTarget.value == "") 
	  	{  		
	  		this.subjectTarget.className = " is-invalid form-control"

	  		this.subjectAlertTarget.className = " alert alert-danger"
	  		
	  		return false
	  	}
	  	else
	  	{	  		  			
	  		this.subjectTarget.className = " is-valid form-control"
	  		this.subjectAlertTarget.className = " "
	  		return true
	  	}
  	}
  	else
  	{
  		return true
  	}  	  
  }


  isCorrespondenceTypeValid(){  	
  	if (this.hasCorrespondenceTypeTarget) 
  	{	
	  	if (this.correspondenceTypeTarget.value === "") 
	  	{  		
	  		this.correspondenceTypeTarget.className = " is-invalid form-control"
	  		this.correspondenceTypeAlertTarget.className = " alert alert-danger"
	  		return false
	  	}
	  	else
	  	{  		
	  		this.correspondenceTypeTarget.className = "is-valid form-control"
	  		this.correspondenceTypeAlertTarget.className = " "
	  		return true
	  	}
  	}
  	else
  	{
  		return true
  	}  
  }

  areThereAnyRecipients(){    
    if (! (this.toEmailsTarget.options.length > 1 || this.ccEmailsTarget.options.length > 1  || this.groupCheckBoxesAreChecked() )) 
    {
      alert("Please enter a recipient, or a valid email address.");      
      this.toEmailsInputTarget.classList.add("alert","alert-danger");
      return false;
    }
    else
    {
      return true;
    }
  }

  groupCheckBoxesAreChecked(){        
    return this.element.querySelectorAll("input[type='checkbox']:checked").length > 0;    
  }

  addValidationColors(){
    if (! (this.toEmailsTarget.options.length > 1 || this.ccEmailsTarget.options.length > 1)) 
    {      
     this.toEmailsInputTarget.classList.add("alert","alert-danger"); 
     this.toEmailsInputTarget.classList.remove("is-valid");             
    }
    else
    {
      this.toEmailsInputTarget.classList.remove("alert","alert-danger");             
    }
  }
}
